<template>
    <div class="box">
        <div class="bullying">
            <h1>Bullying</h1>

            <p>
                O bullying se caracteriza por agressões intencionais, verbais ou físicas,
                feitas de maneira repetitiva, por um ou mais alunos contra um ou mais
                colegas. Ele pode ocorrer por diferenças entre colegas, sejam diferenças
                físicas, cor de pele, tipo de cabelo, religião, entre outros. Pode ocorrer
                por diferenças sociais e intelectuais também. Ou até mesmo quando alguns
                colegas pertencem a um grupo social, como os caracterizados “nerds” pelos
                próprios alunos.
            </p>

            <p>
                Discussões ou brigas pontuais não são bullying. O que caracteriza se é
                bullying ou não é a consistência em que ocorre com as mesmas vítimas e
                autores, e que eles sejam pares, como colegas de classe ou até de
                trabalho. Conflitos entre professor e aluno ou aluno e gestor também não
                são considerados bullying.
            </p>

            <p>
                “Todo bullying é uma agressão, mas nem toda a agressão é considerada
                bullying.”
            </p>

            <p>
                O bullying não é um fenômeno recente, apenas ganhou mais visibilidade nos
                últimos anos.
            </p>

            <h2>BULLYING NA ESCOLA</h2>

            <p>
                Uma pesquisa nacional sobre bullying mostra que, de 5.168 alunos
                entrevistados, de 5ª a 8ª séries de escolas públicas e particulares de
                todas as regiões do País, 10% disseram ser vítimas de bullying e 10%
                agressores. Além de 3% serem vítimas e agressores ao mesmo tempo.
            </p>

            <p>
                O mesmo estudo mostrou o despreparo das escolas e dos professores em lidar
                com essa situação: a maior recorrência do problema, ocorre dentro das
                salas de aulas, mesmo com os professores presentes.
            </p>

            <p>
                Desse modo, o papel do professor na prevenção desse problema é
                fundamental, uma vez que sua intervenção influencia os acontecimentos. A
                vítima aguarda a intervenção nas “agressões” ocorridas em sala de aula, o
                que nem sempre acontece. Essas “agressões” muitas vezes escapam à
                percepção dos professores. Ou então, mesmo percebendo, eles decidem não
                tomar providências para que esse comportamento pare, seja por falta de
                conhecimento de como endereçar esse problema, por falta de direcionamento
                da escola ou até por medo em alguns casos. Quando a omissão do professor
                acontece, os agressores são fortalecidos, já que não são
                responsabilizados. E o problema não é estancado e se perpetua, sendo um
                ciclo vicioso de agressão na sala de aula. Vamos entender o que leva os
                autores do bullying a participarem dessas agressões?
            </p>

            <h2>O QUE LEVA O AGRESSOR A FAZER BULLYING?</h2>

            <p>
                Alguns motivos podem levar o agressor a fazer o bullying: Querer ser mais
                popular, sentir-se poderoso e obter uma boa imagem de si mesmo são alguns
                motivos que especialistas e psicólogos atribuem ao agressor. Essas
                motivações levam a criança a atingir o colega com repetidas humilhações ou
                depreciações.
            </p>

            <p>
                Podemos ter um olhar e entender o agressor como uma pessoa que não
                aprendeu a transformar sua raiva em diálogo. E mais, que não liga muito
                para o sofrimento do outro, a ponto de perpetuar suas atitudes. Pelo
                contrário, ele sente-se satisfeito com a opressão do outro.
            </p>

            <p>
                Dificilmente a escola consegue resolver o problema do agressor sozinha,
                mas é normalmente nesse ambiente que se demonstram os primeiros sinais de
                um praticante de bullying. Portanto, é importante endereçar o ponto com a
                direção da escola, com o aluno e com sua família. O diálogo e a abordagem
                de ajuda e auxílio daquela criança muitas vezes é suficiente para terminar
                ou reduzir esses incidentes na escola. Tente não usar a palavra bullying,
                que, muitas vezes, pode gerar rejeição por parte do agressor e dos pais.
                Faça perguntas para os pais, para que ambos entendam a atitude do aluno,
                sugira aos pais alternativas para controlar esse comportamento e mostre-se
                como aliado. Você quer o bem os alunos, dentro e fora da sala de aula,
                mostre apoio para ajudá-los a passar por esse obstáculo juntos.
            </p>

            <h2>QUAIS SÃO AS CONSEQUÊNCIAS PARA O ALUNO QUE É ALVO DE BULLYING?</h2>

            <p>
                O aluno que sofre bullying, principalmente quando não pede ajuda, enfrenta
                medo e vergonha. Em grande parte das vezes, rejeita ir à escola, usando
                todas as estratégias criativas de uma criança: dores frequentes, choro,
                motivos não necessariamente relacionados ao bullying. A criança também tem
                vergonha de falar que sofre bullying. Muitas das vezes, porque apelidos,
                como “gordo”, por exemplo, corroboram uma autoimagem que este aluno tem de
                si mesmo.
            </p>

            <p>
                Quando mais velha, na pré-adolescência ou adolescência, a criança pode
                querer abandonar os estudos, não se achar bom o suficiente para fazer
                parte do grupo, não sentir que pertence aquela escola ou turma, e
                apresentar baixo rendimento. Uma pesquisa da Associação Brasileira
                Multiprofissional de Proteção à Infância e Adolescência (Abrapia) revela
                que 41,6% das vítimas nunca procuraram ajuda ou falaram sobre o problema,
                nem mesmo com os colegas.
            </p>

            <p>
                Quando as vítimas percebem que seus agressores ficam impunes, elas podem
                escolher outras pessoas mais indefesas e passam a provocá-las, tornando-se
                alvo e agressor ao mesmo tempo e perpetuando o clima de tensão na escola.
            </p>

            <h2>
                O QUE O PROFESSOR PODE FAZER PARA AJUDAR AS VÍTIMAS E TAMBÉM OS
                AGRESSORES?
            </h2>

            <p>
                Como professor, você pode observar crianças que são mais ansiosas, estão
                mais silenciosas ou com medo, aquelas que tentam ser invisíveis. A
                observação dos alunos vai te ajudar a identificar possíveis agressores e
                vítimas, agora, com um olhar um pouco mais qualificado do que você
                enxerga. Verifique se há algum tipo de apelido a algum aluno, palavras
                ofensivas, cutucões, beliscões, ou até mesmo risadas direcionadas a
                alguém. Geralmente, são sinais de bullying.
            </p>

            <p>
                Atue imediatamente quando você presenciar um ato agressivo. Assim, você
                pode controlar o comportamento enquanto ele ocorre e dar o exemplo para
                todos os alunos. Ao surgir uma situação em sala, a intervenção deve ser
                imediata. Se o professor se omite, por exemplo, ele abre espaço para
                outros atos como esse acontecerem. O professor é o primeiro a mostrar
                respeito e dar o exemplo na sala de aula. Ele é também, o detentor do
                clima de sala de aula.
            </p>

            <p>
                O professor também pode incentivar a solidariedade, a generosidade e o
                respeito às diferenças por meio de conversas, campanhas de incentivo à paz
                e à tolerância, trabalhos didáticos, como atividades de cooperação e
                interpretação de diferentes papéis em um conflito. Além disso, podem
                trabalhar a empatia em sala de aula e perguntar como a turma acha que um
                colega se sente.
            </p>

            <p>
                Como agentes de mudança, nós, professores, temos que estar atentos e
                sermos os guardiões da segurança dos nossos alunos e do clima favorável ao
                aprendizado.
            </p>
        </div>

        <div class="references">
            <h2>Referências</h2>
            <ul>
                <li>
                    <a
                        href="https://entretantoeducacao.com.br/professor/bullying-na-sala-de-aula-como-o-professor-pode-ajudar/"
                        >Tonia Casarin</a
                    >
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TipsBullying'
    };
</script>

<style scoped>
    .box {
        max-width: 600px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .bullying h1,
    .bullying h2 {
        margin-bottom: 20px;
    }

    .bullying h2 {
        margin-top: 30px;
    }

    .bullying p {
        font-size: 20px;
        text-align: justify;
        letter-spacing: 0em !important;
    }

    .references h2 {
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .references ul li {
        margin-top: 20px;
        text-align: justify;
    }
</style>
